export const IconResources = {
    TICK: {
        format: 'data',
        title: 'tick-icon',
        viewBox: '0 0 24 24',
        path: 'M19.3 5.3L9 15.6l-4.3-4.3-1.4 1.4 5 5 .7.7.7-.7 11-11z'
    },
};

export default IconResources;
