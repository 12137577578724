/* eslint-disable @typescript-eslint/ban-types */
import XLSX, { WorkSheet } from 'xlsx';
import moment from 'moment';
import ExportableTableColumn from './ExportableTableColumn';

export class ExcelGenerator<T extends object = {}> {
    private readonly name: string;
    private readonly exportableColumns: ExportableTableColumn<T>[];
    private readonly data: T[];
    private readonly date: string | undefined;
    private readonly additionalColumns: string[];

    constructor(name: string, exportableColumns: ExportableTableColumn<T>[], data: T[], date?: string | undefined, additionalColumns?: string[] | undefined) {
        this.name = name;
        this.exportableColumns = exportableColumns;
        this.data = data;
        this.date = date;
        this.additionalColumns = additionalColumns ? additionalColumns : [];
    }

    public generate(): void {
        const headings = this.exportableColumns.map(exportableColumn => exportableColumn.column.Header as string);

        const data = this.data.map((tableDataItem) =>
            this.exportableColumns.map(exportableColumn => {
                const propertyName = exportableColumn.column['accessor'] as string;
                const tableDataItemMap = new Map(Object.entries(tableDataItem));
                const value = tableDataItemMap.get(propertyName);
                return exportableColumn.exportColumn(value);
            })
        );
        const dataWithHeadings = [[...headings, ...this.additionalColumns], ...data];

        const workbook = XLSX.utils.book_new();
        const sheet = XLSX.utils.aoa_to_sheet(dataWithHeadings);
        XLSX.utils.book_append_sheet(workbook, sheet, this.name);

        const headingRange = XLSX.utils.encode_range({ c: 0, r: 0 }, { c: headings.length - 1, r: 0 });
        sheet['!autofilter'] = { ref: headingRange };

        this.exportableColumns.forEach((exportableColumn, index) => ExcelGenerator.setColumnFormat(sheet, index, exportableColumn.exportType));

        const columnWidths = this.calculateAutoFitColumnWidth(dataWithHeadings);
        sheet['!cols'] = columnWidths.map(maxLen => ({ wch: maxLen }));

        const documentDate = this.date ? moment(this.date) : moment();
        XLSX.writeFile(workbook, `${this.name}_${documentDate.format('DD-MM-YYYY[_]hh-mm-A')}.xlsx`);
    }

    private calculateAutoFitColumnWidth(data: (string | number | Date | object)[][]): number[] {
        const columnWidths: number[] = [];
        const defaultWidth = 10;

        data.forEach(row =>
            row.forEach((cell: string | number | Date | object, index: number) => {
                const autoFitLengthForCell = typeof cell === 'string' ? cell.length : defaultWidth;
                columnWidths[index] = columnWidths[index] >= autoFitLengthForCell ? columnWidths[index] : autoFitLengthForCell;
            })
        );

        return columnWidths;
    }

    private static setColumnFormat(sheet: WorkSheet, columnIndex: number, format: string): void {
        const range = XLSX.utils.decode_range(sheet['!ref'] as string);
        /* this loop starts on the second row, as it assumes the first row is a header */
        for (let R = range.s.r + 1; R <= range.e.r; ++R) {
            const cell = sheet[XLSX.utils.encode_cell({ r: R, c: columnIndex })];
            if (cell && format) {
                cell.z = format;
            }
        }
    }
}

export default ExcelGenerator;
