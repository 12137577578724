export class OptionModel {
    value = '';
    label = '';

    constructor(fields?: Partial<OptionModel>) {
        Object.assign(this, fields);
    }
}

export default OptionModel;
