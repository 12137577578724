import OptionModel from './OptionModel';

export class GroupOptionModel {
    label = '';
    options: OptionModel[] = [];

    constructor(fields?: Partial<GroupOptionModel>) {
        Object.assign(this, fields);
    }
}

export default GroupOptionModel;
