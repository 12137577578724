import moment from 'moment';

// used .isoWeekday instead of .weekday because it doesn't depend on the locale
// (.weekday(0) can be either Monday or Sunday).
function workdayCount(start: moment.Moment, end: moment.Moment): number {
    if (start.isSame(end, 'day')) { return 1; }

    // swap start and end if end date early
    if (start.isAfter(end, 'day')) {
        const temp = start;
        start = end;
        end = temp;
    }

    const first = start.clone().endOf('isoWeek'); // end of first week
    const last = end.clone().startOf('isoWeek'); // start of last week
    const days = last.diff(first, 'days') * 5 / 7; // this will always multiply of 7

    let wfirst = first.isoWeekday() - start.isoWeekday() + 1; // check first week total days
    if (start.isoWeekday() === 7) {
        --wfirst; // -1 if start with Sunday
    } else {
        wfirst = wfirst - 2; // -2 if start with not Sunday
    }

    let wlast = end.isoWeekday() - last.isoWeekday() + 1; // check last week total days
    if (end.isoWeekday() === 6) {
        --wlast; // -1 if end with Saturday
    }
    if (end.isoWeekday() === 7) {
        wlast = wlast - 2; // -2 if end with Sunday
    }
    return wfirst + Math.floor(days) + wlast; // get the total
}

export { workdayCount }