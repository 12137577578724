import * as React from 'react';
import Select, { components } from 'react-select';
import OptionModel from './OptionModel';
import IconResources from '../Icon/IconResources'
import Icon from '../Icon/Icon'

const optionStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    zIndex: 100,
    marginLeft: 10,
    color: 'black',
    fontSize: '14px',
    lineHeight: 1.3,
};

const selectStyles = {
    control: (base): JSX.ElementClass => {
        return ({ ...base, minWidth: 'unset' });
    },
    menu: (base): JSX.ElementClass => {
        return ({ ...base, zIndex: 1100 });
    },
    option: (base): JSX.ElementClass => {
        return ({
            ...base,
            cursor: 'pointer',
            backgroundColor: 'transparent',
            '&:hover': { backgroundColor: '#f5f5f5' }
        });
    },
};

const CustomOption = ({ children, isSelected, ...props }): React.ReactNode => {
    return (
        <components.Option {...props}  >
            <div style={optionStyle}>
                <span>{children}</span>
                {isSelected && <Icon size={17} icon={IconResources.TICK} />}
            </div>
        </components.Option>
    );
};

const CustomValueContainer = ({ children, selectProps, ...props }): React.ReactNode => {
    const maxToShow = selectProps.badgeShowMaxSelectionNumber;
    const valueLength = selectProps.value.length;
    const shouldBadgeShow = valueLength > maxToShow;
    return (
        <components.ValueContainer {...props}>
            {shouldBadgeShow && `${valueLength} Items selected`}
            {children}
        </components.ValueContainer >
    );
};

const CustomMultiValueContainer = ({ children, selectProps, ...props }): React.ReactNode => {
    const maxToShow = selectProps.badgeShowMaxSelectionNumber;
    const valueLength = selectProps.value.length;
    const shouldBadgeShow = valueLength > maxToShow;
    return shouldBadgeShow
        ? null
        : (<components.MultiValueContainer {...props}>{children}</components.MultiValueContainer>);
};

interface Props {
    isMulti: boolean;
    closeMenuOnSelect: boolean;
    isLoading: boolean;
    isDisabled: boolean;
    placeholder: string;
    className: string;
    classNamePrefix: string;
    handleChange: Function;
    onMenuClose: Function;
    allowSelectAll: boolean;
    options: OptionModel[];
    selected: string[] | string;
    badgeShowMaxSelectionNumber: number;
}

export class VulcanSelect extends React.Component<Props, {}> {
    private selectAllOption = new OptionModel({ label: 'Select all', value: '*' });

    public static defaultProps: Partial<Props> = {
        isMulti: false,
        closeMenuOnSelect: false,
        isLoading: false,
        isDisabled: false,
        allowSelectAll: false,
        badgeShowMaxSelectionNumber: 3,
        selected: [],
    };

    public render(): JSX.Element {
        const { isMulti, allowSelectAll, options, selected } = this.props;

        const allOptions = isMulti && allowSelectAll && options.length > 1
            ? [this.selectAllOption, ...options]
            : options;

        const selectedArray = isMulti ? selected : [selected];
        const selectedOptions = options.filter(o => selectedArray.includes(o.label));

        return (
            <Select
                {...this.props}
                hideSelectedOptions={false}
                onChange={this.handleSelectChange}
                options={allOptions}
                value={selectedOptions}
                components={{ Option: CustomOption, ValueContainer: CustomValueContainer, MultiValueContainer: CustomMultiValueContainer }}
                styles={selectStyles}
            />
        );
    }

    private handleSelectChange = (selectedOptions: OptionModel[] | OptionModel): void => {
        const { handleChange, isMulti, allowSelectAll, options } = this.props;

        if (isMulti === true) {
            const selected = selectedOptions as OptionModel[];
            if (allowSelectAll && options.length > 0 && selected.some(s => s.value === this.selectAllOption.value)) {
                const alreadySelectAll = selected.filter(o => o.value !== this.selectAllOption.value).length === options.length;
                if (alreadySelectAll) {
                    handleChange([]);
                } else {
                    handleChange(options);
                }
            } else {
                handleChange(selected);
            }
        } else {
            const selected = selectedOptions as OptionModel;
            handleChange(selected);
        }
    }
}

export default VulcanSelect;
